<template>
  <div>
  <v-dialog v-model="visible" max-width="600">
    <v-card v-if="data">
      <v-card-title class="display-1">
        User Details
        <v-spacer/>
        <v-btn v-if="profile" outlined class="mr-2" :to="{name: 'orgmanagerDisconnect', params: {id: org.id, profileId: profile.id}}">Disconnect</v-btn>
      </v-card-title>
      <v-alert v-if="!profile" class="mt-4" type="info" tile>
        This user hasn't connected (yet).
      </v-alert>
      
      <v-card-text v-if="reg" class="mt-4 pb-0">
        <h3>Registration Details</h3>
        <p>
          Code: <code>{{ data.reg.v }}</code>
          • given name: {{ data.reg.gn}}
          • family name: {{ data.reg.fn }}
          • group/team: {{ data.reg.g }}
        </p>
      </v-card-text>
      
      <v-card-text v-if="member" class="mt-4 pb-0">
        <h3>Registration Details</h3>
        Joined at {{member.join_date | localDate('L LTS') }}
      </v-card-text>
      <v-card-text v-else class="mt-4 pb-0">
        Not joined yet
      </v-card-text>
      
      <v-card-text v-if="profile" class="mt-4 pb-0">
        <h3>Profile Details</h3>
        <p>
            Name: {{ profile.name }}
            • source: {{ profile.provider || 'direct' }}
            • joined: {{ data.join_date | localDate('L LTS') }}
            • dob: {{ profile.dob | localDate('L') }}
            • email: {{ profile.email || '-' }}
            • gender: {{ profile.gender || '-' }}
            • id: {{ profile.id }}
            <span v-if="profile.providers && this.profile.providers.length > 0">
              • Connected using: <strong>{{profile.providers.join(' + ')}}</strong>
            </span>
            <span v-else>• No device connected</span>
            <span v-if="profile.manual" class="font-weight-bold">• MANUAL CONNECT</span>
        </p>
      </v-card-text>
      
      <v-card-text v-if="profile" class="mt-4 pb-0">
        <h3>Joined Events</h3>
        <p v-if="!events || !events.length">This user hasn't joined any events yet.</p>
        <p v-else>Click on an event to see all details for that specific event.</p>
        <EventGrid :items="events" @itemclick="openEventDetails"/>
        <ProfileDetailsDialog ref="profileDetails" />
      </v-card-text>
      
      <v-card-actions>
        <v-btn text @click="visible=false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import orgManagerService from "@/services/orgManagerService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import EventGrid from "@/components/EventGrid";
import ProfileDetailsDialog from "@/components/ProfileDetailsDialog";
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'

export default {
  name: "GroupMemberDetailsDialog",
  components: {
    DistanceTextArea,
    DurationTextArea,
    EventGrid,
    ProfileDetailsDialog,
  },
  props: {
      org: Object,
  },
  data() {
    return {
      siteData: siteData,
      visible: false,
      code: null,
      data: null,
      profile: null,
      reg: null,
      member: null,
      events: null,
    };
  },
  async mounted() {
  },
  methods: {

    async open(code) {
      this.code = code;
      this.visible = true;
      
      const response = await orgManagerService.getUser(this.org.id, this.code);
      if (response) {
        this.data = response.data;
        this.reg = response.data.reg;
        this.member = response.data.member;
        this.profile = response.data.profile;
        this.events = response.data.events;
      }
    },

    async refresh() {
        this.open(this.code); 
    },

    async openEventDetails(id) {
      console.log('event CLICK', id);
      this.$refs.profileDetails.openForEvent(this.profile, id);
    },


  },

  computed: {
  },

};
</script>
<style lang="scss">
</style>

