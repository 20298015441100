<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-3 py-2 subtitle">
          Manage users
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="actions">
          <v-btn :disabled="!orgUtil.isPaidPlan()" color="primary" class="mr-4" @click="showUploadRegDialog=true">
            <v-icon small class="mr-2">fa fa-upload</v-icon> 
            Upload
          </v-btn>
        </div>
      </v-toolbar>
      <v-divider/>

      <v-card-text>
        <v-dialog v-if="orgUtil.isProPlan()" v-model="showUploadRegDialog" max-width="600px">
          <v-card>
            <v-card-title>Upload Registration Data</v-card-title>
            <v-card-text>
              <p>
                Please upload a csv or Excel (.xlsx) file with valid data. Note: only full imports (not incremental) are supported currently. 
                Download a <a href="https://sodispstoragep.blob.core.windows.net/public/content/registration-import-template.xlsx">template Excel file</a> or a <a href="https://sodispstoragep.blob.core.windows.net/public/content/registration-import-sample.xlsx">Sample Excel file</a>.
              </p>
              <CsvUploadForm :group-id="org.id" @uploaded="uploaded"/>
            </v-card-text>
          </v-card>
        </v-dialog>        
      </v-card-text>
      
      <v-card-title class="subtitle pt-4">
        <span v-if="$store.getters.isLoading" class="mr-1">  
          <v-icon small>fa fa-circle-notch fa-spin</v-icon>
        </span>
        <span v-else class="mr-1">
          {{registrations.length}} 
        </span>
        registrations
        <v-btn v-if="tenant.id === 'cofi' || true" outlined color="primary" class="ml-4 mr-0" @click="$refs.detailsDialog.new()">
          <v-icon class="mr-2">fa-plus</v-icon> Add
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table v-if="registrations"
        :headers="headers"
        :items="registrations"
        :items-per-page="15"
        :search="search"
        @click:row="item => $refs.groupMemberDialog.open(item.v)"
        >
        <template v-slot:item.actions="{ item }">
          <v-icon small color="primary" title="Copy invite link to clipboard" class="mr-2" @click.stop="copyInviteLink(item.v)">fal fa-copy</v-icon>
          <v-icon v-if="orgUtil.hasDeeplinking()" small color="primary" title="Show QR" class="mr-2" @click.stop="showQr(item.v)">fal fa-qrcode</v-icon>
          <v-icon v-if="false" small color="primary" class="mr-2" @click.stop="$refs.groupMemberDialog.open(item.v)">
            fal fa-info-circle
          </v-icon>
          <v-icon small color="primary" class="" @click.stop="$refs.detailsDialog.edit(item)">
            fal fa-user-edit
          </v-icon>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small color="primary" title="More options" >fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="item.e" @click="resendInvitation(item.v)">
                <v-list-item-icon><v-icon>fal fa-envelope</v-icon></v-list-item-icon>
                <v-list-item-title>Resend invite email</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteRegistration(item.v)">
                <v-list-item-icon><v-icon>fal fa-trash-alt</v-icon></v-list-item-icon>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>     
        </template>
        <template v-slot:item.v="{ item }">
          <code>{{ item.v }}</code>
        </template>
        <template v-slot:item.e="{ item }">
          {{ item.e }}
        </template>
        <template v-slot:item.g="{ item }">
          <v-icon small v-if="!item.g && item.r_id && (event.races.find(x => x.id == item.r_id) || {}).team" color="warning" title="Team name missing.">fadl fa fa-exclamation-triangle</v-icon>
          {{ item.g }}
        </template>
      </v-data-table>

      <RegistrationDetailsDialog :org="org" ref="detailsDialog" @change="loadRegistrations"/>
      <GroupMemberDetailsDialog :org="org" ref="groupMemberDialog"/>
      <v-dialog v-if="joinInfo" v-model="showQrJoinCode" max-width="400px">
        <v-card>
          <v-card-title>Join Event QR</v-card-title>
          <v-card-text>Scan this QR code with your phone to quickly join this organization using our app. This will download the iOS or Android depending on the phone.</v-card-text>
          <v-card-text>This is the <strong>personal</strong> join link for code <strong>{{joinInfo.join_code}}</strong> and can only be used once.</v-card-text>
          <QrCode :data="joinInfo.join_url" />
        </v-card>
      </v-dialog>      
      <br/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import CsvUploadForm from "@/components/CsvUploadForm";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import RegistrationDetailsDialog from "@/components/RegistrationDetailsDialog";
import QrCode from '@/components/generic/QrCode.vue'
import GroupMemberDetailsDialog from "@/components/GroupMemberDetailsDialog";
import { EventBus } from '@/plugins/eventbus.js';
import OrgUtil from "@/util/orgUtil";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "OrgUsers",
  components: {
    QrCode,
    CsvUploadForm,
    ExcelUploadForm,
    RegistrationDetailsDialog,
    GroupMemberDetailsDialog,
  },
  props: {
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      registrations: [],
      search: null,
      showQrJoinCode: false,
      joinInfo: null,
      showUploadRegDialog: false,
      headers: [
        { text: 'Actions', sortable: false, value: 'actions', class: 'col-actions'},
        { text: 'Code', align: 'start', sortable: true, value: 'v',},
        { text: 'First name', align: 'start', sortable: true, value: 'gn',},
        { text: 'Last name', align: 'start', sortable: true, value: 'fn',},
        { text: 'Full name', align: 'start', sortable: true, value: 'n',},
        { text: 'Email', align: 'start', sortable: true, value: 'e',},
        { text: 'Default Team', align: 'start', sortable: true, value: 'g',},
        { text: 'Gender', align: 'start', sortable: true, value: 'ge',},
        { text: 'City', align: 'start', sortable: true, value: 'ci',},
        { text: 'State', align: 'start', sortable: true, value: 'st',},
        { text: 'Country', align: 'start', sortable: true, value: 'cc',},
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user && this.org) {
        const groupId = this.$route.params.id;
        await this.loadRegistrations();
      }
    },

    async uploaded() {
      this.showUploadRegDialog = false;
      this.loadData();
    },

    async loadRegistrations() {
      this.registrations = (await orgManagerService.getRegistrations(this.org.id)).data.data || [];
    },

    async resendInvitation(code) {
      if (confirm(`Are you sure you want to send the invitation to ${code}?`)) {
        const response = (await orgManagerService.sendInviteEventEmailToCode(this.org.id, code)).data;
        this.$helpers.toastResponse(this, response, 'Successfully sent re-invite.');
      }
    },
    async deleteRegistration(code) {
      if (confirm(`Are you sure you want to DELETE the registration record with code '${code}'?`)) {
        const response = (await orgManagerService.deleteRegistration(this.org.id, code)).data;
        this.$helpers.toastResponse(this, response, 'Successfully deleted registration record.');
        this.loadRegistrations();
      }
    },
    /*async reinviteUnconnected() {
      if (confirm(`Are you sure you want to resend the invitation to ALL registrants who have not yet joined?`)) {
        const response = (await eventManagerService.reinviteUnconnected(this.event.id)).data;
        this.$helpers.toastResponse(this, response, 'Successfully sent re-invite.');
      }
    },*/

    
    async showQr(code) {
      this.joinInfo = (await orgManagerService.getJoinInfo(this.org.id, code)).data;
      this.showQrJoinCode = true;      
    },

    async copyInviteLink(code) {
      const joinInfo = (await orgManagerService.getJoinInfo(this.org.id, code)).data;
      this.$helpers.copyToClipboard(joinInfo.join_url, this.$toast);
    },
    

  },
  computed: {
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Users', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    org() {
      this.loadData();
    }
  }
};
</script>
<style lang="scss">
  .col-min100 { min-width: 100px;}
  .col-actions { min-width: 135px;}
</style>

